import { graphql } from 'gatsby'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Container from '@material-ui/core/Container'
import constructionLogo from '../assets/images/construction-industry.svg'

export default function Announcements({ data }) {
  return (
    <Layout>
      <Helmet>
        <title>Announcements</title>
        <meta name="Announcement Page" content="Announcements" />
      </Helmet>
      <Container maxWidth="md">
        {/* {data.allMarkdownRemark.edges[0].node.frontmatter.title} */}
        {data.allMarkdownRemark.edges.map(({ node }) => {
          return (
            <div key={node.id}>
              <h2>{node.frontmatter.title}</h2>
              <p>{node.internal.content}</p>
              <hr />
            </div>
          )
        })}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/announcements/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          internal {
            content
          }
        }
      }
    }
  }
`
